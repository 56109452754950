.object-example{
    display: flex;
}


.object-example .code {
  position: relative;
  flex-grow: 1;
  margin-right: 10px;
}

.object-example .result{
    width: 400px;
}
.object-example .code input {
    background-color: transparent;
    /* position: absolute; */
    border: none;
    color: orange;
    /* text-shadow: 0 1px rgba(0, 0, 0, 0.3);
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: 1em;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    tab-size: 4; */
}

.object-example .code__input1 {
    top: 256px;
    left: 150px;
}
.object-example .code__input2 {
    top: 280px;
    left: 160px;
}

.object-example .code__input3 {
    top: 303px;
    left: 160px;
}

.object-example .code__editable-parts{
    color: #f8f8f2;
    background: none;
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: 1em;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    hyphens: none;


    position: absolute;
    top:   272px;
    padding: 16px;
}